import React, { useContext, useEffect, useState } from 'react';

import ContainerActions from '../../../../components/containeractions';
import CallMethodContext from '../../../../contexts/callmethods';
import DropDownButton from '../../../../components/dropdown';
import { ContainerInput, Input, LabelInput, Container } from "./styles"
import Button from "../../../../components/button";
import ToastContext from '../../../../contexts/toast';
import ApiUser from '../../../../services/ApiUser';
import Dialog from "../../../../components/dialog"
import { IDialogProps, ISectionCall } from "../../../../@interfaces"

import storage from "../../../../storage"

interface IDialog extends IDialogProps {
  show: boolean;
}

const ReInfo: React.FC = () => {
  const { setUserReCalled } = useContext(CallMethodContext);
  const [re, setRe] = useState<string>('');
  const [company, setCompany] = useState<number | string>(0);
  const [companies, setCompanies] = useState<any[]>([]);
  const { showErrorToast } = useContext(ToastContext);
  const [showDialog, setShowDialog] = useState<IDialog>({ show: false });
  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    getCompanies();
  }, [])




  async function getCompanies() {
    try {
      const response = await ApiUser.getCompanies();
      if (response.error) return showErrorToast('Ops.. Algo deu errado ao recuperar empresas');

      if (response.empresas) {
        setCompanies(response.empresas?.map(i => {
          return {
            label: i.nomeEmpresa,
            value: i.relEmpresa
          }
        }));
      }

    } catch (error) {
      showErrorToast('Ops.. Algo deu errado ao recuperar empresas: ' + error)
      throw new Error('get companies: ' + error)
    }
  }




  function concludeFuncValidation(relFuncionario?: number) {
    const currentCallSection = storage.getSectionCall();
    const newCallSection: ISectionCall = {
      ...currentCallSection,
      relFuncionario: Number(relFuncionario)
    };
    storage.setSectionCall(newCallSection);
    setUserReCalled(re);
  }



  function closeDialog() {
    setShowDialog({ show: false });
  }



  async function userValidation() {
    try {
      setLoading(true);
      const response = await ApiUser.validaFuncionario(company, re);

      if (response.error) {
        if (response.mensagem)
          showErrorToast(response.mensagem);
        return
      }

      setShowDialog({
        show: true,
        text: `O funcionário(a) que atendeu se chama ${response.nomeFuncionario}?`,
        actions: [
          { title: "Sim", primary: true, handle: () => concludeFuncValidation(response.relFuncionario) },
          { title: "Não" },
        ]
      })

    } catch (error) {
      setLoading(false);
      throw new Error('userValidtion: ' + error)
    } finally {
      setLoading(false);
    }
  }




  function handleChoice(value: string | number) {
    setCompany(value);
  }





  function handleRe(e: React.ChangeEvent<HTMLInputElement>) {
    setRe(e.target.value);
  }


  return (
    <>
      {showDialog.show && <Dialog
        close={closeDialog}
        text={showDialog.text}
        actions={showDialog.actions}
        onlyOk={showDialog.onlyOk}
      />}
      <ContainerActions>
        <Container>
          <ContainerInput>
            <LabelInput>Para iniciar, informe o RE e a empresa de quem atendeu</LabelInput>
            <Input name={"re"} onChange={handleRe} />
            <DropDownButton handleChoice={handleChoice} width={'60%'} title={'Selecione a empresa ...'} data={companies} />
            {(re && company > 0) ? <Button
              onClick={userValidation}
              label='Validar informações'
              style={{ width: '30%', padding: 10 }}
              isLoading={loading}
            /> : null}
          </ContainerInput>
        </Container>
      </ContainerActions>
    </>
  )
}

export default ReInfo;