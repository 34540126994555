import React, { createContext, useState, useContext } from "react";
import { ICustumer, IListCustumers, ITurno } from "../@interfaces"
import TurnosContext from "./turno"
import ApiUser from "../services/ApiUser"
import Utils from "../utils"

interface ICustumerContext {
  custumerList: ICustumer[],
  isLoading: boolean;
  getCustumerListFromApi: () => void;
}


const CustumerContext = createContext<ICustumerContext>({} as ICustumerContext);

export const CustumerProvide: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [custumerList, setCustumerList] = useState<ICustumer[]>([]);
  const { turnoValue } = useContext(TurnosContext);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function getCustumerListFromApi() {
    try {
      setCustumerList([]);
      setIsLoading(true);
      const clientes = await ApiUser.getCustumerList(turnoValue);
      let data = null;

      if (!clientes.error) {
        data = clientes.clientes?.map((i, index) => {
          return {
            ...i,
            id: index,
            percent: Utils.calcPercent(i.metaCadastrada, i.metaREalizada)
          }
        });
      }

      console.log(data)
      setIsLoading(false);
      if (data) return setCustumerList(data)
      return setCustumerList([]);

    } catch (error) {
      setIsLoading(false);
      throw new Error(JSON.stringify(error));
    }
  }


  return (
    <CustumerContext.Provider value={{ custumerList, getCustumerListFromApi, isLoading }}>
      {children}
    </CustumerContext.Provider>
  )
}

export default CustumerContext;