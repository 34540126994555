import React, { useState } from 'react';

import CircularProgress from '../circularprogress';
import { useTheme } from 'styled-components';
import { BsTelephoneForward, BsTelephoneX } from "react-icons/bs"
import { Card, RowColumn } from './styles';
import { ICustumer } from "../../@interfaces"
import moment from 'moment';
import { TailSpin } from 'react-loader-spinner'

interface IProps {
  color: 'red' | 'blue' | 'yellow' | 'none';
  onClick?: () => void;
  data: ICustumer;
  delay: number;
  key: any;
  loading?: boolean;
}


const CardCustumer: React.FC<IProps> = ({ color, onClick, data, delay }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false)

  let date = '--';

  if (new Date(data.ultimaSupervisao?.replace('Z', '')).getFullYear() > 1900) {
    date = moment(data.ultimaSupervisao?.replace('Z', '')).format('DD/MM/YYYY HH:mm');
  }


  async function _onClick() {
    try {
      setLoading(true);
      if (onClick)
        await onClick()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }


  return (
    <Card priority={data.prioridade} onClick={_onClick} delay={delay} disabled={loading}>

      <CircularProgress
        progress={data.percent ? data.percent : 0}
        size={40}
        textSize={12}
        fillColor={theme.colors.primary}
        backColor={'rgba(41, 145, 214, .1)'}
        label={'Meta'}
      />
      <RowColumn>
        <h2 className={'title'}>{data.nomeCliente}</h2>
        <span className='subtitle first'>{data.nomeLocal}</span>
        <span className='subtitle first'>
          <strong> <BsTelephoneForward size={10} /> Ult. chamada: </strong>
          {date}
        </span>
      </RowColumn>

      {loading && <TailSpin
        height={15}
        width={15}
        color={theme.colors.primary}
      />}

    </Card>
  )
}

export default CardCustumer;