import React, { createContext, useEffect, useState } from "react";
import Utils from "../utils"
import { ICurrentCalled, ICustumer } from "../@interfaces"
import storage from "../storage"


type TCallingState = 'Chamando ...' | 'Estabelecendo conexão ...' | 'none';


interface ICallMethodContext {
  currentPic: string | null;
  currentReadQrCode: string | null;
  setCurrentPic: (base64: string, signal?: boolean) => void;
  setCurrentReadQrCode: (base64: string, signal?: boolean) => void;
  setCurrentCalled: (current: ICurrentCalled | null) => void;
  cleanCache: () => void;
  setUserReCalled: (param: string | null) => void;
  setShowRaiting: (param: boolean) => void;
  userReCalled: string | null;
  currentCalled: ICurrentCalled | null,
  signalPic: boolean;
  signalQrCode: boolean;
  showRaiting: boolean;
  inCall: boolean;
  setInCall: (inCallParam: boolean) => void;
  setCallingState: (state: TCallingState) => void;
  callingState: TCallingState;
}





const CallMethodContext = createContext<ICallMethodContext>({} as ICallMethodContext);




export const CallMethodProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [currentPic, _setCurrentPic] = useState<string | null>(null);
  const [currentReadQrCode, _setCurrentReadQrCode] = useState<string | null>(null);
  const [signalPic, _setSignalPic] = useState<boolean>(false);
  const [signalQrCode, _setSignalQrCode] = useState<boolean>(false);
  const [currentCalled, _setCurrentCalled] = useState<ICurrentCalled | null>(null);
  const [userReCalled, setUserReCalled] = useState<string | null>(null);
  const [showRaiting, setShowRaiting] = useState<boolean>(false);
  const [currentCustumerCall, setCurrentCustumerCall] = useState<ICustumer | null>(null);
  const [currentChaveExec, setCurrentChaveExec] = useState<number | null>(null);
  const [inCall, setInCall] = useState<boolean>(false);
  const [callingState, setCallingState] = useState<TCallingState>('none')


  useEffect(() => {
    const _lastCalled = storage.getLastCalled();
    if (_lastCalled) setCurrentCalled(_lastCalled);
    else setCurrentCalled(null);
  }, [])


  function cleanCache() {
    _setCurrentPic(null);
    _setCurrentReadQrCode(null);
    setCurrentCalled(null);
    setUserReCalled(null);
    setInCall(false);
  }


  function setCurrentCalled(obj: ICurrentCalled | null) {
    _setCurrentCalled(obj);
    if (obj) storage.setLastCalled(obj)
    else storage.deleteLastCalled();
  }


  function setCurrentPic(base64: string, signal?: boolean) {
    _setCurrentPic(base64);
    Utils.saveFileBase64(base64, String(Date.now()));
    if (signal) {
      _setSignalPic(true);
      setTimeout(() => +_setSignalPic(false), 200);
    }
  }

  function setCurrentReadQrCode(base64: string, signal?: boolean) {
    _setCurrentReadQrCode(base64);
    if (signal) {
      _setSignalQrCode(true);
      setTimeout(() => +_setSignalQrCode(false), 200);
    }
  }




  return (
    <CallMethodContext.Provider value={{
      setCurrentCalled,
      currentCalled,
      signalPic,
      signalQrCode,
      setCurrentReadQrCode,
      setCurrentPic,
      cleanCache,
      currentPic,
      currentReadQrCode,
      setUserReCalled,
      userReCalled,
      showRaiting,
      setShowRaiting,
      inCall,
      setInCall,
      setCallingState,
      callingState
    }}>
      {children}
    </CallMethodContext.Provider>
  )
}

export default CallMethodContext;