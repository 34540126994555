import styled, { css, keyframes } from "styled-components";

export const Container = styled.div`
  height: 100vh;
  background: linear-gradient(to right bottom, rgba(0,0,0,0), #fff);
  display: flex;
  align-items: center;
  justify-content: center;



 


`

export const ContentContainer = styled.div`
  width: 800px;
  height: 450px;
  background: #fff;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
`

export const FormContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    align-self: center;
    font-weight: 200;
  }
`

export const DrawContainer = styled.div`
  height: 100%;
  background: #303641;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 0 8px 8px 0;
  flex-direction: column;
  >h1{
    margin-bottom: 10px;
    color: #fff;
    font-weight: 400;
    font-size: 1.125rem;
  }
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
 
`

interface IFieldProps {
  slideDirection?: 'right' | 'left'
}

export const Field = styled.input<IFieldProps>`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  width: 250px;
  padding: 10px 0;
  outline: none;
  ${({ slideDirection }) => slideDirection === 'right' ? slideRight :
    slideDirection === 'left' ? slideLeft : ''
  }
  
  ::placeholder{
    color: #BABABA;
    font-family: Quicksand, sans-serif;
  }
`

export const Dropdown = styled.button<IFieldProps>`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  width: 250px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background .2s;
  ${({ slideDirection }) => slideDirection === 'right' ? slideRight :
    slideDirection === 'left' ? slideLeft : ''
  } 
  :hover {
    background: #eee;
    cursor: pointer;
  }
`

export const Button = styled.button`
  width: 250px;
  background: #2991D6;
  padding: 5px;
  color: #fff;
  margin-top: 15px;

  :hover{
    cursor: pointer;
  }
`


const slideLeftKeyframes = keyframes`
  from{
    transform: translateX(300px);
  }to{
    transform: translateX(0);
  }
`

const slideRightKeyframes = keyframes`
  from{
    transform: translateX(-300px);
  }to{
    transform: translateX(0);
  }
`

export const slideLeft = css`
  animation: ${slideLeftKeyframes} .2s forwards;
`

export const slideRight = css`
  animation: ${slideRightKeyframes} .2s forwards;
`

export const PasswordViewButton = styled.button`
  position: absolute;
  bottom: 6px;
  right: 0;
  padding: 0;
  ${slideLeft}
  :hover{
    cursor: pointer;
  }
`