import React, { useContext, useState } from 'react';
import { BsFillStarFill } from "react-icons/bs"

import { Container, StarsContainer, Modal, RatingBox, RaitingButton, TextArea } from './styles';
import { cloneDeep } from 'lodash';
import Button from '../button';
import CallMethodContext from '../../contexts/callmethods';
import CustumerContext from '../../contexts/custumers';
import DatDashContext from '../../contexts/dash';
import storage from '../../storage';
import ApiUser from '../../services/ApiUser';
import ToastContext
  from '../../contexts/toast';

const FinalService: React.FC = () => {
  const [starsFunc, setStarsFunc] = useState<boolean[]>([true, false, false, false, false]);
  const [starsCall, setStarsCall] = useState<boolean[]>([true, false, false, false, false]);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('')
  const { setShowRaiting, userReCalled } = useContext(CallMethodContext);
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);
  const { getCustumerListFromApi } = useContext(CustumerContext);
  const { getDataDash } = useContext(DatDashContext);


  function toggleStarsFunc(index: number) {
    const _stars: any = cloneDeep(starsFunc);
    const value = !starsFunc[index];

    if (value) {
      for (let i = index; i >= 0; i--) {
        _stars[i] = true;
      }
    } else {
      let _index = index + 1;
      if (_index > (_stars.length - 1)) {
        _index = index;
      }
      for (let i = _index; i < _stars.length; i++) {
        _stars[i] = false;
      }
    }
    return setStarsFunc(_stars);
  }



  function toggleStarsCall(index: number) {
    const _stars: any = cloneDeep(starsCall);
    const value = !starsCall[index];

    if (value) {
      for (let i = index; i >= 0; i--) {
        _stars[i] = true;
      }
    } else {
      let _index = index + 1;
      if (_index > (_stars.length - 1)) {
        _index = index;
      }
      for (let i = _index; i < _stars.length; i++) {
        _stars[i] = false;
      }
    }
    return setStarsCall(_stars);
  }



  async function finalizaSup() {
    try {
      setLoading(true);

      const body = getBody();

      const response = await ApiUser.finalizaSupervisaoRemota(body);

      if (response.error) {
        setLoading(false);
        showErrorToast(`Erro ao finalizar supervisão: ${response.message}`);
        return
      }

      showSuccessToast('Supervisão Finalizada');
      getCustumerListFromApi();
      getDataDash();

      setShowRaiting(false);
    } catch (error) {

      setLoading(false);
      throw new Error('finalizaSup: ' + error)
    }
  }



  function getBody() {
    const callSection = storage.getSectionCall();
    const { chaveExec, relFuncionario, checklists } = callSection;
    let checks: any = [];
    let body: any = {};
    if (checklists && checklists.length > 0) {
      checks = checklists;
    }
    if (userReCalled) {
      body = {
        situacaoChamada: 4,
        avaliacaoFuncionario: starsFunc.filter(i => i === true).length,
        avaliacaoObjetivoChamada: starsCall.filter(i => i === true).length,
        chaveExecucao: chaveExec,
        relFuncionario,
        comentario: comment,
        checklistsRespondidos: checks
      };
    } else {
      body = {
        situacaoChamada: 5,
        chaveExecucao: chaveExec,
        comentario: comment,
      };
    }
    return body;
  }



  return (
    <Modal>
      <Container>
        <RatingBox>

          {userReCalled && <h2>Avaliação do Funcionário</h2>}
          {userReCalled && <StarsContainer>
            <RaitingButton selected={starsFunc[0]} onClick={() => toggleStarsFunc(0)}>
              <BsFillStarFill size={24} />
            </RaitingButton>
            <RaitingButton selected={starsFunc[1]} onClick={() => toggleStarsFunc(1)} >
              <BsFillStarFill size={24} />
            </RaitingButton>
            <RaitingButton selected={starsFunc[2]} onClick={() => toggleStarsFunc(2)} >
              <BsFillStarFill size={24} />
            </RaitingButton>
            <RaitingButton selected={starsFunc[3]} onClick={() => toggleStarsFunc(3)} >
              <BsFillStarFill size={24} />
            </RaitingButton>
            <RaitingButton selected={starsFunc[4]} onClick={() => toggleStarsFunc(4)} >
              <BsFillStarFill size={24} />
            </RaitingButton>
          </StarsContainer>
          }


          {userReCalled && <h2>Avaliação do objetivo da chamada</h2>}
          {userReCalled && <StarsContainer>
            <RaitingButton selected={starsCall[0]} onClick={() => toggleStarsCall(0)}>
              <BsFillStarFill size={24} />
            </RaitingButton>
            <RaitingButton selected={starsCall[1]} onClick={() => toggleStarsCall(1)} >
              <BsFillStarFill size={24} />
            </RaitingButton>
            <RaitingButton selected={starsCall[2]} onClick={() => toggleStarsCall(2)} >
              <BsFillStarFill size={24} />
            </RaitingButton>
            <RaitingButton selected={starsCall[3]} onClick={() => toggleStarsCall(3)} >
              <BsFillStarFill size={24} />
            </RaitingButton>
            <RaitingButton selected={starsCall[4]} onClick={() => toggleStarsCall(4)} >
              <BsFillStarFill size={24} />
            </RaitingButton>
          </StarsContainer>}


          <TextArea value={comment} onChange={(e: any) => setComment(e.target.value)} />
          <Button label={'Finalizar atendimento'} onClick={finalizaSup} isLoading={loading} />
        </RatingBox>

      </Container>
    </Modal>
  )
}

export default FinalService;