import axios from "axios";
import { ICompaniesResponse, ICPFValidationResponse, ILoginResponse } from "../@interfaces";
import { UserAuth } from "../@types";
import storage from "../storage";
import ApiHK from "./APIHK";

const APIAuth = {
  getHeaderLogin: async function (user: UserAuth) {
    const userJson = {
      "usuario": user.id,
      "senha": user.password,
      "empresa": user.companyCode,
      "licenca": storage.getLicenseToken()
    }

    let formattedJSon = JSON.stringify(userJson);
    formattedJSon = formattedJSon.trim();

    const base64 = window.btoa(formattedJSon);

    const header = {
      'Authorization': `Bearer ${base64}`,
      'Content-Type': 'application/json'
    };

    return header;
  },

  validCPF: async function (cpf: string): Promise<ICPFValidationResponse> {
    try {
      const header = { "Content-Type": "application/json" };
      const data = { "codigoaplicativo": 5, "cpf": cpf };

      const response = await ApiHK.post<ICPFValidationResponse>('/licencas', data, { headers: header });

      if (response.status === 200 && response.data.token) {
        storage.setLicenseToken(response.data.token);
        return response.data;
      } else {
        return { error: true, message: response.data.mensagem }
      }

    } catch (e: any) {
      if (e.response.status !== 500) {
        return { error: true, message: "Usuário não encontrado" }
      }
      return { error: true, message: "Algo deu errado." };
    }
  },

  getCompanies: async function (): Promise<ICompaniesResponse> {
    const token = storage.getLicenseToken();
    const header = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const response = await ApiHK.get<ICompaniesResponse>(`/empresas`, { headers: header });

      if (response.status === 200 && response.data.empresas) {
        return response.data;
      } else {
        return { error: true, message: response.data.mensagem }
      }

    } catch (error) {
      return { error: true, message: error }
    }
  },

  validLogin: async function (user: UserAuth): Promise<ILoginResponse> {
    try {

      const header = await this.getHeaderLogin(user);
      const url = storage.getClientUrl();
      const tokenLicenca = storage.getLicenseToken();

      const response = await axios.get(`${url}/login`, { headers: header });


      if (response.data.status === 0) {
        let tokenAuth = { login: response.data.token, licenca: tokenLicenca };
        storage.setFullAccessToken(btoa(JSON.stringify(tokenAuth)));

        return response.data
      }
      return { error: true, message: response.data.mensagem };
    } catch (e) {
      return { error: true, message: e };
    }

  },
}

export default APIAuth;