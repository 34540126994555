
import React, { useContext, useEffect, useRef } from "react"

import {
  Action,
  ActionsContainer,
  ActionButton,
  Answer,
  FooterContainer,
  RowContainer,
  Question,
  QuestionContainer,
  SubTitle,
  TextAnswer,
  Title,
  TitleContainer,
  ContentQuestions,
  HelpContainer,
  Container
} from "./styles";


import { TailSpin } from "react-loader-spinner";
import { IoMdAttach, IoIosText } from 'react-icons/io';
import ToolTip from "../../../../components/tootip"
import { IRespostasCheckList, IChecklist, INotAswered, IAttachs, ICheckListComponent, IDialogProps } from "../../../../@interfaces";
import checklistMock from './checklist.json';
import { useState } from "react";
import CloseButton from "../../../../components/closebutton";
import ObservationInput from "./observationinput"
import ContainerActions from "../../../../components/containeractions"
import { cloneDeep } from "lodash"
import Utils from "../../../../utils"
import Photos from "./photos"
import Dialog from "../../../../components/dialog"
import moment from "moment";
import ApiUser from "../../../../services/ApiUser";
import ToastContext from "../../../../contexts/toast";
import CallMethodsContext from "../../../../contexts/callmethods"
import { BsInfoCircle } from "react-icons/bs"
import storage from "../../../../storage";

type TShake = "attach" | "obs";

interface IDialog extends IDialogProps {
  show: boolean;
}


const Checklists: React.FC<ICheckListComponent> = (props) => {

  const [shakeAttach, setShakeAttach] = useState<boolean>(false);
  const [shakeObs, setShakeObs] = useState<boolean>(false);
  const [showObsInput, setShowObsInput] = useState(false);
  const [attachs, setAttachs] = useState<IAttachs[] | null>(null);
  const [imgs, setImgs] = useState<IAttachs[] | null | []>(null);

  const [arrivedAtLast, setArrivedAtLast] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const [howManyQuestions, setQtQuestions] = useState(0);
  const [currentCheckList, setCurrentCheckList] = useState<IChecklist | null>(null);

  const [currentPage, setCurrentPage] = useState<any>(0)

  const [needPhoto, setNeedPhoto] = useState(false);
  const [canTakePhoto, setCanTakePhoto] = useState(false);

  const [needComments, setNeedComments] = useState(false);
  const [canComment, setCanComment] = useState(false);
  const [showPhotos, setShowPhotos] = useState<boolean>(false);
  const refInputFile = useRef<HTMLInputElement>(null);
  const [naoRespondidas, setNaoRespondidas] = useState<any>([]);
  const [showDialog, setShowDialog] = useState<IDialog>({ show: false });
  const [horaIni, setHoraIni] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false)
  const { showErrorToast, showSuccessToast } = useContext(ToastContext)
  const { cleanCache, currentPic, currentReadQrCode, signalPic, signalQrCode } = useContext(CallMethodsContext)



  useEffect(() => {
    loadChecklist();
  }, [])



  useEffect(() => {
    if (signalPic) {
      if (canTakePhoto) {
        setShowDialog({
          show: true,
          text: "Deseja anexar imagem a questão corrente?",
          actions: [
            { title: "Sim", primary: true, handle: () => saveBase64(currentPic!) },
            { title: "Não" },
          ]
        })
      }
    }
  }, [signalPic])




  async function loadChecklist() {
    //@ts-ignore    
    const itens = props.checkList.itens.filter(i => i.tipo !== 4);
    let withoutAssign = props.checkList;
    withoutAssign.itens = itens;
    setCurrentCheckList(withoutAssign)
    setQtQuestions(withoutAssign.itens.length);
    const data = withoutAssign.itens[currentPage];
    setCurrentQuestion(data.pergunta);

    setHoraIni(moment().format("YYYY-MM-DDTHH:mm:ss"))
  }



  useEffect(() => {
    setNeedPhoto(false);
    setCanTakePhoto(false);
    setCanComment(false);
    setNeedComments(false);



    if (currentCheckList && currentCheckList.itens && currentCheckList.itens.length > 0) {

      // Tipo 1 = Questoes com opcoes de escolha
      if (currentCheckList.itens[currentPage].tipo === 1) {
        const { opcoes } = currentCheckList.itens[currentPage];

        const index = opcoes.findIndex((o: any) => o.selected === true);

        if (index !== -1) {

          const midia = opcoes[index].midia;
          if (midia === 2 || midia === 3) {
            setCanTakePhoto(true);
            if (midia === 3) setNeedPhoto(true)
          }

          const comment = opcoes[index].comentario;
          if (comment === 2 || comment === 3) {
            setCanComment(true);
            if (comment === 3) setNeedComments(true)
          }

        }
      } else {

        const { textAnswer, midia, comentario } = currentCheckList.itens[currentPage];

        if (textAnswer) {

          if (midia === 2 || midia === 3) {
            setCanTakePhoto(true);
            if (midia === 3) setNeedPhoto(true)
          }

          const comment = comentario;
          if (comment === 2 || comment === 3) {
            setCanComment(true);
            if (comment === 3) setNeedComments(true)
          }
        }

      }
    }
  }, [currentCheckList, currentPage])




  function setNotAnswered(question: INotAswered) {
    let _data = cloneDeep(naoRespondidas);

    if (_data.length > 0) {
      if (_data.findIndex((i: INotAswered) => i.page === question.page) === -1) {
        _data.push(question);
      }
    } else {
      const auxArr = [];
      auxArr.push(question);
      _data = auxArr;
    }

    setNaoRespondidas(_data);
  }



  function isValidCurrentAnswer(last: boolean) {
    if (!currentCheckList) return;
    const {
      opcoes,
      obs,
      obrigatorio,
      tipo,
      textAnswer,
      item,
    } = currentCheckList.itens[currentPage];


    if (tipo === 2 || tipo === 3) {


      if ((!textAnswer && obrigatorio)) {
        setNotAnswered({ page: currentPage, question: currentQuestion });
        if (last) return false
      }

      if (textAnswer) {
        if (needComments && !obs) {
          activeShake("obs");
          return false;
        }

        if (needPhoto) {
          if (!attachs) {
            activeShake('attach');
            return false;
          }
          const midias = attachs.filter(i => i.id_item === item) || [];
          if (midias.length === 0) {
            activeShake('attach');
            return false;
          }
        }
      }

    }

    if (tipo === 1) {

      const index = opcoes.findIndex(o => o.selected === true);

      if (index !== -1) {
        if (needComments && !obs) {
          activeShake("obs");
          return false;
        }


        if (needPhoto) {
          if (!attachs) {
            activeShake('attach');
            return false;
          }
          const midias = attachs.filter(i => i.id_item === item) || [];
          if (midias.length === 0) {
            activeShake('attach');
            return false;
          }
        }

      }


      if (obrigatorio && index === -1) {
        setNotAnswered({ page: currentPage, question: currentQuestion });
        if (last) {
          showErrorToast('Existem questões obrigatórias não respondidas.')
          return false;
        }
      }
    }
    return true;
  }





  function Next() {
    if (!currentCheckList) return;
    const nextPage = currentPage + 1;

    if (nextPage < howManyQuestions) {

      if (!isValidCurrentAnswer(false)) return;


      setCurrentQuestion(currentCheckList.itens[nextPage].pergunta);
      setCurrentPage(nextPage);



      if ((nextPage + 1) >= howManyQuestions) {
        setArrivedAtLast(true);
      }
    }

  }




  function Back() {
    const nextPage = currentPage - 1;
    if ((nextPage >= 0) && currentCheckList) {
      setCurrentPage(nextPage);
      setCurrentQuestion(currentCheckList.itens[nextPage].pergunta);
    }
  }




  function selectOption(index: number) {
    const newData = cloneDeep(currentCheckList)
    if (newData) {
      const { selected } = newData.itens[currentPage].opcoes[index];
      const { obrigatorio } = newData.itens[currentPage];
      const isSelectedNow = !selected;
      newData.itens[currentPage].opcoes.map(o => o.selected = false);
      newData.itens[currentPage].opcoes[index].selected = isSelectedNow;
      newData.itens[currentPage].obs = '';
      setCurrentCheckList(newData);

      if (!isSelectedNow && obrigatorio) {
        setNotAnswered({ page: currentPage, question: currentQuestion });
      } else {
        deleteNotAnswered();
      }
    }
  }




  function answerWithText(event: any) {
    let newValue = event.target.value
    const newData = cloneDeep(currentCheckList);

    if (!newData) return;

    if (newData.itens[currentPage].tipo === 3) {
      newValue = newValue.replace(/\D+/g, '');
    }

    newData.itens[currentPage].textAnswer = newValue;

    setCurrentCheckList(newData);
    deleteNotAnswered();
  }





  function putObs(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const obs = e.target.value;
    const newData = Object.assign({}, currentCheckList);
    newData.itens[currentPage].obs = obs
    setCurrentCheckList(newData);
  }





  function activeShake(action: TShake) {
    if (action === 'attach') {
      setShakeAttach(true)
      setTimeout(() => {
        setShakeAttach(false);
      }, 400)
    }

    if (action === 'obs') {
      setShakeObs(true)
      setTimeout(() => {
        setShakeObs(false);
      }, 400)
    }
  }






  function openObs() {
    setShowObsInput(true);
  }





  function closeObs() {
    setShowObsInput(false);
  }





  function saveBase64(_base64: string) {
    if (!currentCheckList) return;

    const obj: IAttachs = {
      id_item: currentCheckList.itens[currentPage].item,
      base64: _base64,
      id: Date.now()
    }

    let _attachs = cloneDeep(attachs) || null;

    if (!_attachs) {
      _attachs = [];
      _attachs.push(obj);
    } else {
      _attachs.push(obj);
    }

    const imgs: IAttachs[] | undefined = _attachs?.filter(i => i.id_item === currentCheckList.itens[currentPage].item);
    if (imgs) {
      setImgs(imgs);
    } else {
      setImgs([])
    }
    setAttachs(_attachs);
  }



  async function handleLogoImage(event: any) {
    if (!currentCheckList) return;
    const _base64: any = await Utils.fileToBase64(event.target);
    saveBase64(_base64);
  }





  function deleteAttach(id: number) {
    let _imgs = cloneDeep(imgs);
    let _attachs = cloneDeep(attachs);

    const imgsFiltered: IAttachs[] | undefined = _imgs?.filter(i => i.id !== id);
    if (imgsFiltered)
      setImgs(imgsFiltered);
    else
      setImgs([]);

    const _attachsFiltered: IAttachs[] | undefined = _attachs?.filter(i => i.id !== id);
    if (_attachsFiltered)
      setAttachs(_attachsFiltered);
    else
      setAttachs([]);
  }





  function openAttachs() {
    if (refInputFile.current) {
      refInputFile.current.click();
    }
  }



  function openPhotos() {
    if (!currentCheckList) return;
    const imgs: IAttachs[] | undefined = attachs?.filter(i => i.id_item === currentCheckList.itens[currentPage].item);
    if (imgs)
      setImgs(imgs);

    setShowPhotos(true);
  }






  function closePhotos() {
    setShowPhotos(false);
  }




  function deleteNotAnswered() {
    if (!naoRespondidas) return;
    let _data = cloneDeep(naoRespondidas);
    let newData = _data.filter((i: INotAswered) => i.page !== currentPage);
    setNaoRespondidas(newData);
  }


  function closeDialog() {
    setShowDialog({ show: false });
  }









  ////// SendMethods
  function goToFinal() {
    if (!currentCheckList) return;

    if (naoRespondidas && naoRespondidas.length > 0) {
      showErrorToast('Existem questões obrigatórias não respondidas.')
      return;
    }

    if (!isValidCurrentAnswer(true)) return;

    let aux = 0;

    currentCheckList.itens.map(i => {
      let aux2
      if (i.tipo === 1) {
        aux2 = i.opcoes.filter(o => o.selected === true);
        aux += aux2.length;
      } else {
        if (i.textAnswer) aux += 1;
      }
    });

    const percent = Utils.calcPercent(currentCheckList.itens.length, aux);

    if (percent === 0) {
      showErrorToast('Nenhuma questão respondida.')
    }

    setShowDialog({
      show: true,
      text: 'Deseja enviar suas respostas?',
      actions: [{ title: "Sim", primary: true, handle: sendCheckList }, { title: "Não" }]
    })

  }





  async function sendCheckList() {
    const pacote: any = JSONProcessToSend();
    if (pacote && (typeof pacote !== 'boolean')) {

      try {
        setLoading(true);
        const response = await ApiUser.sendChecklists(pacote);
        setLoading(false);

        if (!response.error) {
          storage.addCheckListAnswer(response.chaveChecklist!);
          props.closeModal();
          showSuccessToast("Respostas enviadas");
        }
      } catch (error) {
        setLoading(false);
        showErrorToast(`Não foi possível enviar suas respostas: ${error}`)
      }
    }
  }






  function JSONProcessToSend() {
    if (!currentCheckList) return false;

    let answreds: any = [];

    currentCheckList.itens.map(i => {
      const { answer, imgs, planosAcoes } = getAnswerAndPics(i);
      if (answer) {
        answreds.push({
          item: i.item,
          observacao: i.obs ? i.obs : '',
          resposta: answer ? answer.toString() : '',
          fotos: imgs,
          planosAcoes: planosAcoes ? planosAcoes : []
        })
      }
    });

    const nowDate = moment().format("YYYY-MM-DDTHH:mm:ss");

    const completeJson = {
      "modelo": currentCheckList.modelo,
      "observacao": "",
      "entidade": currentCheckList.entidade, //currentCheckList.entidade,// noticia
      "datahora": nowDate,
      "pontoValidacao": 0,
      "hrinicio": horaIni,
      "hrfim": nowDate,
      "latitude": 0,
      "longitude": 0,
      "respostas": answreds,
      "nomeModelo": currentCheckList.nomeModelo,
      "programacao": currentCheckList.programacao,
    }


    const arrResponse: any = [];

    arrResponse.push(completeJson);

    return { checklists: arrResponse };;
  }





  function getAnswerAndPics(item: any) {
    const imgs = attachs?.filter(i => i.id_item === item.item)
      .map(img => img.base64);

    let arrOfImages: any = [];
    let planos: any = []

    if (imgs && imgs.length > 0) {
      imgs.forEach(img => {
        arrOfImages.push({
          arqBase64: img
        })
      });
    }

    if (item.tipo === 2 || item.tipo === 3) return { answer: item.textAnswer, imgs: arrOfImages };


    const answer = item.opcoes.filter((o: any) => o.selected === true)[0];

    return { answer: answer ? answer.opcao : undefined, imgs: arrOfImages, planosAcoes: planos }
  }














  return (
    <>
      <input
        ref={refInputFile}
        type='file'
        name='attachs'
        id='attachs'
        accept="image/png, image/jpeg"
        onChange={handleLogoImage}
        style={{ display: 'none' }}
      />

      {showDialog.show && <Dialog
        close={closeDialog}
        actions={showDialog.actions}
        text={showDialog.text}
        onlyOk={showDialog.onlyOk}
      />}

      {showPhotos && <Photos deleteAttach={deleteAttach} close={closePhotos} handleAttach={openAttachs} imgs={imgs} />}

      {showObsInput && <ObservationInput onChange={putObs} close={closeObs} value={currentCheckList?.itens[currentPage].obs || ""} />}
      <ContainerActions>
        <Container>
          <CloseButton handle={props.closeModal} />


          <TitleContainer>
            <div>
              <Title>Questão {currentPage + 1} / </Title>
              <SubTitle>{howManyQuestions}</SubTitle>
            </div>
          </TitleContainer>

          <QuestionContainer>


            <Question>
              {currentQuestion}
            </Question>

            <ContentQuestions>
              {currentCheckList?.itens[currentPage].tipo === 1 && currentCheckList?.itens[currentPage].opcoes.map((i, index) => (
                <Answer key={index} onClick={() => selectOption(index)} selected={i.selected}>
                  {i.resposta}
                </Answer>
              ))}


              {((currentCheckList?.itens[currentPage].tipo === 2) || (currentCheckList?.itens[currentPage].tipo === 3)) && (
                <TextAnswer
                  value={currentCheckList.itens[currentPage].textAnswer}
                  onChange={answerWithText}
                />
              )}
            </ContentQuestions>
          </QuestionContainer>




          <HelpContainer>

          </HelpContainer>



          <FooterContainer>
            <RowContainer>
              {<ToolTip text={"Anexo de arquivos"}>
                <ActionButton className={`${shakeAttach ? 'shakeClass' : ''}`} onClick={openPhotos} disabled={!canTakePhoto}>
                  <IoMdAttach color={canTakePhoto ? "#fff" : "#666"} size={16} />
                </ActionButton>
              </ToolTip>}

              <ToolTip text={"Observações"}>
                <ActionButton className={`${shakeObs ? 'shakeClass' : ''}`} onClick={openObs} disabled={!canComment}>
                  <IoIosText color={canComment ? "#fff" : "#666"} size={16} />
                </ActionButton>
              </ToolTip>

              {currentCheckList?.itens[currentPage].ajuda ? (
                <ToolTip text={currentCheckList?.itens[currentPage].ajuda}>
                  <BsInfoCircle color={"#fff"} />
                </ToolTip>
              ) : null
              }
            </RowContainer>


            <ActionsContainer>
              {(currentPage > 0 && !loading) && <Action onClick={Back}>Voltar</Action>}
              {!loading && (howManyQuestions !== (currentPage + 1)) && <Action primary onClick={Next}>Avançar</Action>}
              {!loading && (howManyQuestions === (currentPage + 1)) && <Action green primary onClick={goToFinal}>Finalizar</Action>}
              {
                loading && <TailSpin
                  height={15}
                  width={15}
                  color="#fff"
                />
              }
            </ActionsContainer>
          </FooterContainer>
        </Container>
      </ContainerActions>
    </>
  )
}

export default Checklists;