import appApi from "../services/appApi";
import APIHK from "./APIHK";
import storage from "../storage"
import { IChecklistsResponse, IbodyChecklistRequest, IRespostasCheckList, IResponseSendChecklist, IResponseGetSocketID, IListaLocaisSupervisaoRemotaResponse, ITurnosTrabalhoResponse, IIndicadoresSupervisaoRemotaResponse, IlocalSupervisaoRemotaResponse, IEmpresasResponse, IValidaFuncionario, IIniciaSupervisaoRemota, IChamadaAtivaRequest, IChamadaAtivaSupervisaoRemotaResponse, IFinalizaSupervisaoRemotaRequest, IFinalizaSupervisaoRemotaResponse, IResponseCallUser } from "../@interfaces"
import APIMensageira from "./APIMensageira";

export default class ApiUser {

  //ProcessarRespostasChecklist
  static getChecklists = async (body: IbodyChecklistRequest): Promise<IChecklistsResponse> => {
    const urlClient = storage.getClientUrl();

    try {
      const response = await appApi.post<IChecklistsResponse>(`${urlClient}/Checklists`, body);

      if (response.data && response.data.status === 0) {
        return {
          checklists: response.data.checklists
        }
      } else {
        return { error: true, message: response.data.mensagem };
      }

    } catch (error) {
      return { error: true, message: error }
    }
  }


  static sendChecklists = async (body: IRespostasCheckList): Promise<IResponseSendChecklist> => {
    const urlClient = storage.getClientUrl();

    try {
      const response = await appApi.post<IResponseSendChecklist>(`${urlClient}/ProcessarRespostasChecklist`, body);

      if (response.data && response.data.status === 0) {

        return {
          error: false,
          chaveChecklist: response.data.chaveChecklist
        }
      } else {
        return { error: true, message: response.data.mensagem };
      }

    } catch (error) {
      return { error: true, message: error }
    }
  }



  static getSocketID = async (chave: string): Promise<IResponseGetSocketID> => {
    const header = {
      "Authorization": `Bearer ${storage.getLicenseToken()}`
    }
    try {
      const response = await APIHK.get<IResponseGetSocketID>(`getsocketid/${chave}`, { headers: header });

      if (response.data && response.data.status === 0) {
        const { socketID } = response.data;
        if (socketID) {
          return {
            error: false,
            socketID: response.data.socketID
          }
        } else {
          return {
            error: true,
            message: 'Dispositivo não encontrado no banco de licença'
          }
        }

      } else {
        return { error: true, message: response.data.mensagem };
      }

    } catch (error) {
      return { error: true, message: error }
    }
  }




  static sendCallSolicitation = async (mySocketToken: string, pushID: string): Promise<IResponseCallUser> => {
    const header = {
      "Authorization": `Bearer ${storage.getLicenseToken()}`
    }
    const body = {
      device: pushID,
      socketToken: mySocketToken
    }

    try {
      const response = await APIMensageira.post<any>(`sendCallSolicitation`, body, { headers: header });

      if (response.data && response.data.status === 0) {
        return {
          error: false
        }
      }
      return {
        error: true,
        message: response.data.message
      }
    } catch (error) {
      return { error: true, message: error }
    }
  }




  static getCustumerList = async (turno: number): Promise<IListaLocaisSupervisaoRemotaResponse> => {
    try {
      const body = { CodTurno: turno };
      const urlClient = storage.getClientUrl();

      const response = await appApi.post<IListaLocaisSupervisaoRemotaResponse>(`${urlClient}/ListaLocaisSupervisaoRemota`, body)


      if (response.data && response.data.status === 0) {
        return {
          error: false,
          clientes: response.data.clientes
        }
      }
      return { error: true }
    } catch (error) {
      return { error: false }
    }
  }



  static getCustumer = async (CodTurno: number, relCliente: number, relLocal: number, progSupRemota: number): Promise<IlocalSupervisaoRemotaResponse> => {
    try {
      const urlClient = storage.getClientUrl();
      const body = { CodTurno, relCliente, progSupRemota, relLocal };
      const response = await appApi.post<IlocalSupervisaoRemotaResponse>(`${urlClient}/LocalSupervisaoRemota`, body)

      if (response.data && response.data.status === 0) {
        return {
          error: false,
          ...response.data
        }
      }
      return { error: true }
    } catch (error) {
      return { error: false }
    }
  }


  static getTurnos = async (): Promise<ITurnosTrabalhoResponse> => {
    try {
      const urlClient = storage.getClientUrl();

      const response = await appApi.get<ITurnosTrabalhoResponse>(`${urlClient}/TurnoTrabalho`)


      if (response.data && response.data.status === 0) {
        return {
          error: false,
          turnosTrabalho: response.data.turnosTrabalho
        }
      }
      return { error: true }
    } catch (error) {
      return { error: false }
    }
  }


  static getCompanies = async (): Promise<IEmpresasResponse> => {
    try {
      const urlClient = storage.getClientUrl();

      const response = await appApi.get<IEmpresasResponse>(`${urlClient}/EmpresasSupervisaoRemota`)


      if (response.data && response.data.status === 0) {
        return {
          error: false,
          empresas: response.data.empresas
        }
      }
      return { error: true }
    } catch (error) {
      return { error: false }
    }
  }


  static getIndicadores = async (turno: number): Promise<IIndicadoresSupervisaoRemotaResponse> => {
    try {
      const urlClient = storage.getClientUrl();
      const body = { CodTurno: turno };
      const response = await appApi.post<IIndicadoresSupervisaoRemotaResponse>(`${urlClient}/IndicadoresSupervisaoRemota`, body)

      if (response.data && response.data.status === 0) {
        return {
          error: false,
          ...response.data
        }
      }
      return { error: true }
    } catch (error) {
      return { error: false }
    }
  }


  static validaFuncionario = async (relEmpresa: number | string, re: string): Promise<IValidaFuncionario> => {
    try {
      const urlClient = storage.getClientUrl();
      const body = { relEmpresa, re };
      const response = await appApi.post<IValidaFuncionario>(`${urlClient}/ValidaFuncionarioSupervisaoRemota`, body)

      if (response.data && response.data.status === 0) {
        return {
          error: false,
          ...response.data
        }
      }
      return { error: true, mensagem: response.data.mensagem }
    } catch (error) {
      return { error: false }
    }
  }



  static finalizaSupervisaoRemota = async (body: IFinalizaSupervisaoRemotaRequest): Promise<IFinalizaSupervisaoRemotaResponse> => {
    try {
      const urlClient = storage.getClientUrl();

      const response = await appApi.post<IFinalizaSupervisaoRemotaResponse>(`${urlClient}/FinalizaSupervisaoRemota`, body)

      if (response.data && response.data.status === 0) {
        return {
          error: false,
          ...response.data
        }
      }
      return { error: true, message: response.data.mensagem }
    } catch (error) {
      return { error: false, message: error }
    }
  }



  static sendChamadaAtiva = async (chaveExecucao: number): Promise<IChamadaAtivaSupervisaoRemotaResponse> => {
    try {
      const urlClient = storage.getClientUrl();
      const body = { chaveExecucao };
      const response = await appApi.post<IChamadaAtivaSupervisaoRemotaResponse>(`${urlClient}/ChamadaAtivaSupervisaoRemota`, body)

      if (response.data && response.data.status === 0) {
        return {
          error: false,
          ...response.data
        }
      }
      return { error: true, mensagem: response.data.mensagem }
    } catch (error) {
      return { error: false }
    }
  }


  static iniciaSupervisao = async (ProgSupRemota: number): Promise<IIniciaSupervisaoRemota> => {
    try {
      const urlClient = storage.getClientUrl();
      const body = { ProgSupRemota };
      const response = await appApi.post<IValidaFuncionario>(`${urlClient}/IniciarSupervisaoRemota`, body)

      if (response.data && response.data.status === 0) {
        return {
          error: false,
          ...response.data
        }
      }
      return { error: true, mensagem: response.data.mensagem }
    } catch (error) {
      return { error: false }
    }
  }



  static finalizaSupervisao = async (comentario: string, situacaoChamada: number, avaliacaoFuncionario: number, avaliacaoObjetivoChamada: number): Promise<IIniciaSupervisaoRemota> => {
    try {
      const urlClient = storage.getClientUrl();
      const callSection = storage.getSectionCall();
      const { chaveExec, relFuncionario, checklists } = callSection;

      const body = {
        situacaoChamada,
        avaliacaoFuncionario,
        avaliacaoObjetivoChamada,
        chaceExecucao: chaveExec,
        relFuncionario,
        comentario,
        checklistsRespondidos: checklists
      };

      const response = await appApi.post<IValidaFuncionario>(`${urlClient}/FinalizaSupervisaoRemota`, body)

      if (response.data && response.data.status === 0) {
        return {
          error: false,
          ...response.data
        }
      }
      return { error: true, mensagem: response.data.mensagem }
    } catch (error) {
      return { error: false }
    }
  }



}